<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link class="brand-logo">
                <vuexy-logo />
                <h2 class="brand-text text-primary ml-1">
                    {{ appName }}
                </h2>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div
                    class="w-100 d-lg-flex align-items-center justify-content-center px-5"
                >
                    <b-img
                        style="max-height: 400px"
                        fluid
                        :src="imgUrl"
                        :alt="$t(`pages.${pagename}.pagetitle`)"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- logout-->
            <b-col
                lg="4"
                class="d-flex align-items-center auth-bg px-2 p-lg-5 d-cloum"
            >
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
                        {{ $t(`pages.logout.msg1`) }}
                    </b-card-title>
                    <b-card-text class="mb-2">
                        {{ $t(`pages.logout.msg2`) }}
                    </b-card-text>
                    <!-- submit buttons -->
                    <b-button variant="primary" @click="logout">
                        {{ $t('common.backhome') }}
                    </b-button>
                </b-col>
            </b-col>
            <!-- /logout-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BImg, VBTooltip, BCardText, BCardTitle, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import { $themeConfig } from '@themeConfig'
import store from '@/store/index'
import axios from '@axios'

export default {
  setup() {
    // App Name
    const {
      appName, appLogoImage, companyName, companyUrl, author,
    } = $themeConfig.webConfigs[document.location.hostname] ? $themeConfig.webConfigs[document.location.hostname] : $themeConfig.app
    return {
      pagename: 'logout',
      appName,
      appLogoImage,
      companyName,
      companyUrl,
      author,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    VuexyLogo,
    BCardText,
    BCardTitle,
    BButton,
  },
  data() {
    return {
      status: '',
      password: 'admin',
      userEmail: 'admin@demo.com',
      // sideImg: require('@/assets/images/pages/not-authorized.svg'),
      sideImg: require('@/assets/images/pages/logout.png'),
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/logout-v2.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    try {
      // let vuethis = this
      this.initialize()
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    initialize() {
      try {
        // let vuethis = this
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
        localStorage.removeItem('userData')
        localStorage.removeItem('userToken')

        this.$cookies.remove('userData')
        this.$cookies.remove('userToken')
        this.$socket.disconnect()
        setTimeout(() => {
          this.$socket.connect()
        }, 200)
        axios.get('/updatetoken')
        // Reset ability
        this.$ability.update(initialAbility)
      } catch (error) {
        console.log(error)
      }
    },
    logout() {
      // this.$router.push('/')
      this.$router.push({ name: 'Login' })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
